import React from "react";
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  Image,
  Heading,
  HStack,
  Stack,
} from "@chakra-ui/react"

const Markdown = React.lazy(() => import("react-markdown"));

interface InformationPageLinkCardProps {
  imageLink: string;
  heading: string;
  description: string;
  pageLink: string;
};

const InformationPageLinkCard = (
    {imageLink, heading, description, pageLink}: InformationPageLinkCardProps
) => {

  return (
    <Card maxW="m" borderRadius="xl">
      <HStack spacing={8} alignItems="start" paddingY={5} paddingX={10}>
        <Box w="40%" maxH="80%" mr={0}>
          <Image src={imageLink} alt={`Photo for ${heading}`} borderRadius="xl"/>
        </Box>

        <Box w="60%">
          <Stack spacing={2}>
            <Heading as="h2" size="md">{heading}</Heading>
            <Markdown>{description}</Markdown>
            <Button 
              as={ReactRouterLink} 
              to={pageLink}
              colorScheme="green" 
              alignSelf="flex-end"
              mt={4}
            >
              Read more
            </Button>
          </Stack>
        </Box>
      </HStack>
    </Card>
  )
};


export default InformationPageLinkCard;
