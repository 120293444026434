import React from "react";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Image,
  Link,
  Heading,
  HStack,
  Stack,
  Text
} from "@chakra-ui/react"

import {SolutionTypeData} from '../../types'

const Markdown = React.lazy(() => import("react-markdown"));

interface SolutionTypeCardProps {
    solutionTypeData: SolutionTypeData
};

const SolutionTypeCard = ({solutionTypeData}: SolutionTypeCardProps) => {
  return (
    <Card maxW="m" borderRadius="xl">
      <HStack spacing={2} alignItems="start">
        <Box w="50%" ml={3} mt={3}>
          <CardHeader>
            <Heading as="h2" size="lg">{solutionTypeData.name}</Heading>
          </CardHeader>
          <CardBody>
              <Stack spacing={3}>
                <Markdown>{solutionTypeData.description}</Markdown>
                <Text>
                  <Link href={solutionTypeData.solutionTypeInformationLink} color="teal">
                    {`Read more about ${solutionTypeData.name}`}
                  </Link>
                </Text>
              </Stack>
          </CardBody>
        </Box>

        <Box w="50%">
          <CardHeader>
            <Image 
              src="/assets/placeholder.jpg" 
              alt="Placeholder" 
              borderRadius="xl"
            />
          </CardHeader>
          <CardBody >
            <HStack spacing={2} w="100%" alignItems="start">
              <Stack w="50%" spacing={4}>
                <Text>
                  Permanence<br/> 
                  <strong>{solutionTypeData.permanence}</strong>
                </Text>
                <Text>
                  Additionality<br/> 
                  <strong>{solutionTypeData.additionality}</strong>
                </Text>
                <Text>
                  Scaling Potential<br/> 
                  <strong>{solutionTypeData.scalingPotential}</strong>
                </Text>
              </Stack>

              <Stack w="50%" spacing={4}>
                <Text>
                  Co-benefits<br/>
                  <strong>{solutionTypeData.coBenefits}</strong>
                </Text>
                <Text>
                  Verifiability<br/>
                  <strong>{solutionTypeData.verifiability}</strong>
                </Text>
                <Text>
                  Typical Net Negativity<br/>
                  <strong>{solutionTypeData.typicalNetNegativity}</strong>
                </Text>
              </Stack>
            </HStack>
          </CardBody>
        </Box>
      </HStack>
    </Card>
  )
};


export default SolutionTypeCard;
