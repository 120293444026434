import React from "react";
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Circle,
  Image,
  Link as ChakraLink,
  Heading,
  HStack,
  Stack,
  Text
} from "@chakra-ui/react"

import {PortfolioPartnerData, SolutionTypeConfig, SolutionTypeInfo} from '../../types'

const Markdown = React.lazy(() => import("react-markdown"));

interface PartnerCardProps {
    partnerData: PortfolioPartnerData
};

const PartnerCard = ({partnerData}: PartnerCardProps) => {
  const solutionTypeInfo: SolutionTypeInfo = SolutionTypeConfig[partnerData.solutionType]

  return (
    <Card maxW="m" borderRadius="xl">
      <HStack spacing={2} alignItems="start">
        <Box w="50%" ml={3} mt={3}>
          <CardHeader>
            <Heading as="h2" size="lg">{partnerData.name}</Heading>
            <HStack spacing="2">
              <Circle size="8px" bg={solutionTypeInfo.color} /> 
              <Text>{solutionTypeInfo.name}</Text>
            </HStack>
          </CardHeader>
          <CardBody>
            <Stack spacing={3}>
              <Markdown>{partnerData.description}</Markdown>
              <Text>
                <ChakraLink href={partnerData.partnerInformationLink} color="teal">
                  {`Read more about ${partnerData.name}`}
                </ChakraLink>
              </Text>
            </Stack>
          </CardBody>
        </Box>

        <Box w="50%">
          <CardHeader>
            <Image 
              src={partnerData.staticImageLink} 
              alt={`Photo of ${partnerData.name}`} 
              borderRadius="xl"
              />
            
          </CardHeader>
          <CardBody >
            <HStack spacing={2} w="100%" alignItems="start">
              <Stack w="50%" spacing={4}>
                <Text>
                  Solution Type<br/> 
                  <ChakraLink 
                    color="teal.500" 
                    as={ReactRouterLink} 
                    to={`/solution-type/${partnerData.solutionType}`}
                  >
                    <strong>{solutionTypeInfo.name}</strong>
                  </ChakraLink>
                </Text>
                <Text>
                  Permanence<br/> 
                  <strong>{partnerData.permanence}</strong>
                </Text>
                <Text>
                  Additionality<br/> 
                  <strong>{partnerData.additionality}</strong>
                </Text>
              </Stack>

              <Stack w="50%" spacing={4}>
                <Text>
                  Company Stage <br/>
                  <ChakraLink 
                    color="teal.500" 
                    href="https://zopeful.com/blog/carbon-removal-portfolio-fund-methodology"
                  >
                    <strong>{partnerData.companyStage}</strong>
                  </ChakraLink>
                </Text>
                <Text>
                  Location <br/>
                  <strong>{partnerData.location}</strong>
                </Text>
              </Stack>
            </HStack>
          </CardBody>
        </Box>
      </HStack>
    </Card>
  )
};


export default PartnerCard;
