import React, { useEffect, useState } from "react";
import { Box, Heading, Stack, Text } from "@chakra-ui/react"
import { useParams } from 'react-router-dom';

import {PortfolioPartnerData, SolutionTypeData, SolutionType} from '../../types'
import BasePage from "../BasePage"
import LoadingSpinner from "../../components/LoadingSpinner";
import MethodologyBadges from "../../components/MethodologyBadges";
import BackButton from "../../components/buttons/BackButton";
import InformationPageLinkCard from "../../components/cards/InformationPageLinkCard";
import SolutionTypeCard from "./SolutionTypeCard";

type SolutionTypePageState = {
  solutionTypeData: SolutionTypeData | null;
  partnersWithSolutionType: PortfolioPartnerData[];
  loading: boolean;
}

function SolutionTypePage() {
  const { solutionTypeId } = useParams<{ solutionTypeId: SolutionType }>();
  const [state, setState] = useState<SolutionTypePageState>({
    solutionTypeData: null,
    partnersWithSolutionType: [],
    loading: true,
  });


  useEffect(() => {
    const getSolutionTypeData = (solutionTypeList:  SolutionTypeData[]) => {
      return solutionTypeList.find((solutionType: SolutionTypeData) => solutionType.id === solutionTypeId) || null;
    }

    const getPartnersWithSolutionType = (solutionTypeId: SolutionType) => {
      fetch("/data/partnerData.json")
        .then(response => response.json())
        .then(jsonData => setState(prevState => ({
            ...prevState,
            partnersWithSolutionType: jsonData.partnersInPortfolio.filter(
              (partner: PortfolioPartnerData) => (
                partner.solutionType === solutionTypeId && partner.isActive
              )
            ),
          }
        )))
        .catch(error => {
          console.error(error);
          setState(prevState => ({...prevState}));
        });
    }

    fetch("/data/solutionTypeData.json")
      .then(response => response.json())
      .then(jsonData => {
        if (solutionTypeId !== undefined) {
          getPartnersWithSolutionType(solutionTypeId);
        }
        setState(
          {
            loading: false, 
            partnersWithSolutionType: [],
            solutionTypeData: getSolutionTypeData(jsonData)
          }
        );
      })
      .catch(error => {
        console.error(error)
        setState({loading: false, partnersWithSolutionType: [], solutionTypeData: null});
      });
  }, [setState, solutionTypeId]);

  const renderContent = () => {
    if (state.solutionTypeData === null) {
      return <Text>Solution type not found. Please reach out to hey@zopeful.com</Text>
    }
    return <SolutionTypeCard solutionTypeData={state.solutionTypeData}/>;
  }

  return BasePage(
    <Box>
      <BackButton text="Back to dashboard" url="/dashboard" />

      <Box paddingY={6}>
        <Heading as="h1" size="lg" pb="4">Solution Types</Heading>
        <Box>
          {state.loading ? <LoadingSpinner /> : renderContent()}
        </Box>
      </Box>

      <Box paddingY={6}>
        <Heading as="h1" size="lg" pb="4">Learn more</Heading>
        <Stack spacing={4}>
          {
            state.partnersWithSolutionType.map((similarPartner: PortfolioPartnerData) => (
              <InformationPageLinkCard 
                imageLink={similarPartner.staticImageLink}
                heading={similarPartner.name || ""}
                description={""}
                pageLink={`/partner/${similarPartner.id}`}
                key={similarPartner.id}
              />
            ))
          }
        </Stack>
      </Box>

      <Box paddingY={6}>
        <Heading as="h1" size="lg" pb="4">Methodology</Heading>
        <MethodologyBadges />
      </Box>
    </Box>
  );
}

export default SolutionTypePage;
